import './scss/index.scss';
import { dcFactory } from '@deleteagency/dc';
import AnimatedOfficeHeroComponent from './js/animated-office-hero.component';

import { TabbedSearch } from './js/tabbed-search.component';
import { VideoControl } from './js/video-control';

import { PopularLocations } from './../popular-locations/js/popular-locations.component';

dcFactory.register(AnimatedOfficeHeroComponent);
dcFactory.register(TabbedSearch);
dcFactory.register(PopularLocations);
dcFactory.register(VideoControl);
