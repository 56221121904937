import { DcBaseComponent } from '@deleteagency/dc';

export default class SocialSharingComponent extends DcBaseComponent {
    _isOpened = false;

    static getNamespace() {
        return 'footer';
    }

    onInit() {
        const accordion = document.querySelector('.site-footer__accordion');

        if (accordion) {
            accordion.addEventListener('click', (e) => {
                const activePanel = e.target.closest('.site-footer__accordion-panel');
                if (!activePanel) return;
                toggleAccordion(activePanel);
            });
        }

        function toggleAccordion(panelToActivate) {
            const activeButton = panelToActivate.querySelector('button');
            const activePanel = panelToActivate.querySelector('.site-footer__accordion-content');
            const activePanelIsOpened = activeButton.getAttribute('aria-expanded');

            // Close all other accordion panels
            document.querySelectorAll('.site-footer__accordion-panel').forEach((panel) => {
                const button = panel.querySelector('button');
                const content = panel.querySelector('.site-footer__accordion-content');
                if (panel !== panelToActivate) {
                    button.setAttribute('aria-expanded', false);
                    content.setAttribute('aria-hidden', true);
                    // find all nested links and set tabindex to -1
                    content.querySelectorAll('a').forEach((link) => {
                        link.setAttribute('tabindex', -1);
                    });
                }
            });

            if (activePanelIsOpened === 'true') {
                activeButton.setAttribute('aria-expanded', false);
                activePanel.setAttribute('aria-hidden', true);
                activePanel.querySelectorAll('a').forEach((link) => {
                    link.setAttribute('tabindex', -1);
                });
                activePanel.querySelectorAll('button').forEach((link) => {
                    link.setAttribute('tabindex', -1);
                });
            } else {
                activeButton.setAttribute('aria-expanded', true);
                activePanel.setAttribute('aria-hidden', false);
                activePanel.querySelectorAll('a').forEach((link) => {
                    link.setAttribute('tabindex', 0);
                });
                activePanel.querySelectorAll('button').forEach((link) => {
                    link.setAttribute('tabindex', 0);
                });
            }
        }
    }
}
