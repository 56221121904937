import { DcBaseComponent } from '@deleteagency/dc';
import keyBy from 'lodash/keyBy';

export default class SidebarNavComponent extends DcBaseComponent {
    static getNamespace() {
        return 'sidebar-nav';
    }

    static getRequiredRefs() {
        return ['root', 'triggers', 'items'];
    }

    onInit() {
        this.stack = [this.refs.root];

        this.triggers = this.refs.triggers;
        this.items = this.refs.items;
        this.itemsById = keyBy(this.items, (el) => el.getAttribute('data-id'));
        this.back = this.refs.back || [];

        this.triggers.forEach((el) => {
            const id = el.getAttribute('data-target');
            this.addListener(el, 'click', this._onTriggerClick.bind(this, id));
        });

        this.back.forEach((el) => this.addListener(el, 'click', this._onBackClick));

        const accordion = document.querySelector('.site-sidebar__accordion');

        if (accordion) {
            accordion.addEventListener('click', (e) => {
                const activePanel = e.target.closest('.site-sidebar__accordion-panel');
                if (!activePanel) return;
                toggleAccordion(activePanel);
            });
        }

        function toggleAccordion(panelToActivate) {
            const activeButton = panelToActivate.querySelector('button');
            const activePanel = panelToActivate.querySelector('.site-sidebar__accordion-content');
            const activePanelIsOpened = activeButton.getAttribute('aria-expanded');

            // Close all other accordion panels
            document.querySelectorAll('.site-sidebar__accordion-panel').forEach((panel) => {
                const button = panel.querySelector('button');
                const content = panel.querySelector('.site-sidebar__accordion-content');
                if (panel !== panelToActivate) {
                    button.setAttribute('aria-expanded', false);
                    content.setAttribute('aria-hidden', true);
                    // find all nested links and set tabindex to -1
                    content.querySelectorAll('a').forEach((link) => {
                        link.setAttribute('tabindex', -1);
                    });
                }
            });

            if (activePanelIsOpened === 'true') {
                activeButton.setAttribute('aria-expanded', false);
                activePanel.setAttribute('aria-hidden', true);
                activePanel.querySelectorAll('a').forEach((link) => {
                    link.setAttribute('tabindex', -1);
                });
                activePanel.querySelectorAll('button').forEach((link) => {
                    link.setAttribute('tabindex', -1);
                });
            } else {
                activeButton.setAttribute('aria-expanded', true);
                activePanel.setAttribute('aria-hidden', false);
                activePanel.querySelectorAll('a').forEach((link) => {
                    link.setAttribute('tabindex', 0);
                });
                activePanel.querySelectorAll('button').forEach((link) => {
                    link.setAttribute('tabindex', 0);
                });
            }
        }
    }

    _onTriggerClick(id) {
        this._showItem(id);
    }

    _showItem(id) {
        const itemToShow = this.itemsById[id];
        if (!itemToShow) {
            throw new Error(`No items for id ${id}`);
        }

        const current = this.stack[this.stack.length - 1];
        current.classList.add('is-prev');
        itemToShow.classList.add('is-active');
        this.stack.push(itemToShow);
        // set tabindex to 0 for all links in the current item
        itemToShow.querySelectorAll('button').forEach((link) => {
            link.setAttribute('tabindex', 0);
        });
        itemToShow.querySelectorAll('a').forEach((link) => {
            link.setAttribute('tabindex', -1);
        });
        current.querySelectorAll('a').forEach((link) => {
            link.setAttribute('tabindex', -1);
        });
        current.querySelectorAll('button').forEach((link) => {
            link.setAttribute('tabindex', -1);
        });
    }

    _onBackClick = () => {
        this._goBack();
    };

    _goBack() {
        if (!this.stack.length) return;
        const currentItem = this.stack.pop();
        const prevItem = this.stack[this.stack.length - 1];
        currentItem.classList.remove('is-active');
        prevItem.classList.remove('is-prev');
        // set tabindex to -1 for all links in the current item
        currentItem.querySelectorAll('a').forEach((link) => {
            link.setAttribute('tabindex', -1);
        });
        currentItem.querySelectorAll('button').forEach((link) => {
            link.setAttribute('tabindex', -1);
        });
        prevItem.querySelectorAll('a').forEach((link) => {
            link.setAttribute('tabindex', 0);
        });
        prevItem.querySelectorAll('button').forEach((link) => {
            link.setAttribute('tabindex', 0);
        });

        // close all accordion panels in current
        currentItem.querySelectorAll('.site-sidebar__accordion-panel').forEach((panel) => {
            const button = panel.querySelector('button');
            const content = panel.querySelector('.site-sidebar__accordion-content');
            button.setAttribute('aria-expanded', false);
            content.setAttribute('aria-hidden', true);
            content.querySelectorAll('a').forEach((link) => {
                link.setAttribute('tabindex', -1);
            });
        });
    }
}
