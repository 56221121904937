import { DcBaseComponent } from '@deleteagency/dc';
import $ from 'jquery';

export class VideoControl extends DcBaseComponent {
    static getNamespace() {
        return 'video-control';
    }

    onInit() {
        const video = $(this.element).find('.multimedia-hero__video-element');
        const playBtn = $(this.element).find('.multimedia-hero__video-control');
        const mobPlayBtn = $(this.element).find('.multimedia-hero__video-control_mobile');

        const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
        if (prefersReducedMotion) {
            video.prop('autoplay', false);
            video.prop('loop', false);
            video.prop('playsinline', false);
            playBtn
                .addClass('is-paused')
                .removeClass('is-playing')
                .text('Play');
            mobPlayBtn
                .addClass('is-paused')
                .removeClass('is-playing')
                .text('Play');
        }

        const toggleVideoPlayback = () => {
            if (video[0].paused) {
                video[0].play();
                playBtn
                    .addClass('is-playing')
                    .removeClass('is-paused')
                    .text('Pause');
                mobPlayBtn
                    .addClass('is-playing')
                    .removeClass('is-paused')
                    .text('Pause');
                video.removeAttr('controls');
            } else {
                video[0].pause();
                video.removeAttr('controls');
                playBtn
                    .addClass('is-paused')
                    .removeClass('is-playing')
                    .text('Play');
                mobPlayBtn
                    .addClass('is-paused')
                    .removeClass('is-playing')
                    .text('Play');
            }
        };

        playBtn.on('click', toggleVideoPlayback);
        mobPlayBtn.on('click', toggleVideoPlayback);
    }
}
