import { pageLocker } from '@deleteagency/page-locker';

const ID = 'menu';

export class MenuService {
    /**
     * @param overlay {Overlay}
     */
    constructor(overlay) {
        this._overlay = overlay;
        this._activeMenuId = null;
        this._menuMap = new Map();
        this._menuChangeSubscribers = [];
        this._overlay.subscribeOnOverlayClick(this._onOverlayClick);
    }

    _onOverlayClick = () => {
        this.closeActive();
    };

    closeActive() {
        const activeMenu = this._getActiveMenu();
        if (activeMenu) {
            this._close(activeMenu);
        }
    }

    triggerOpen(id) {
        const menu = this._getMenu(id);
        if (!menu) {
            throw new Error(`Menu with id ${id} not found`);
        }
        this._open(menu);
    }

    triggerClose(id) {
        const activeMenu = this._getActiveMenu();
        if (activeMenu.id === id) {
            this._close(activeMenu);
        }
    }

    register(id, instance) {
        this._menuMap.set(id, { id, instance });
    }

    _getMenu(id) {
        return this._menuMap.get(id);
    }

    getMenuElement(id) {
        return this._menuMap.get(id).instance.element;
    }

    subscribeOnActiveMenuChange(cb) {
        this._menuChangeSubscribers.push(cb);
    }

    _getActiveMenu() {
        return this._activeMenuId ? this._getMenu(this._activeMenuId) : null;
    }

    isActive(id) {
        const activeMenu = this._getActiveMenu();
        return activeMenu && activeMenu.id === id;
    }

    _open(newMenu) {
        const activeMenu = this._getActiveMenu();
        if (activeMenu === newMenu) {
            return;
        }

        if (activeMenu) {
            activeMenu.instance.onClose(true);
        }
        pageLocker.lock(ID);
        this._overlay.show(ID);
        newMenu.instance.onOpen();
        this._setActiveMenuId(newMenu.id);
        document.querySelector('.site-header-container').classList.add('is-opened');
    }

    _close(menu, forced = false) {
        menu.instance.onClose(forced);
        this._setActiveMenuId(null);
        pageLocker.unlock(ID);
        this._overlay.hide(ID);
        document.querySelector('.site-header-container').classList.remove('is-opened');
    }

    _setActiveMenuId(value) {
        this._activeMenuId = value;
        this._menuChangeSubscribers.forEach((cb) => cb(this._activeMenuId));
    }
}
