/* general styles */
import './general/scss/normalize.css';
import './general/scss/index.scss';

/* stuff and polyfills */
// intersection observer polyfill
import 'intersection-observer';
import './general/js/get-attribute-names-polyfill';
import './general/js/lazysizes';
import './general/js/what-input';
import './general/js/review-read-more';

// API requests */
import './general/js/current-contact-id';

/* components */
import './components/lazysizes';
import './components/video';
import './components/modal';
import './components/overlay';
import './components/site-footer';
import './components/site-listing';
import './components/social-circles';
import './components/flickity';
import './components/flick-car';
import './components/richtext';
import './components/contact-card';
import './components/avatar';
import './components/floating-hero';
import './components/page-section';
import './components/section';
import './components/subnav';
import './components/gtm-trigger';
import './components/reviews';
import './components/pod';
import './components/contact-info';
import './components/cookie-banner';
import './components/ch-content';
import './components/form';
import './components/predictive';
import './components/local-office-search';
import './components/branch';
import './components/quick-search';
import './components/action-pod';
import './components/pic-over';
import './components/core-search';
import './components/enter-viewport';
import './components/site-header';
import './components/site-logo';
import './components/site-nav';
import './components/site-sidebar';
import './components/menu-trigger';
import './components/deep-nav';
import './components/circle-image';
import './components/myww-signup-sidebar';
import './components/property-name';
import './components/user-data';
import './components/branch-finder';
import './components/aspect-ratio';
import './components/site-area';
import './components/request-valuation';
import './components/top-branch';
import './components/flash-messages';
import './components/address-predictive-field';
import './components/saved-items';
import './components/save-btn';
import './components/site-noti';
import './components/skip-to-main';
import './components/anchor-scroll';
import './components/prepopulated-form';
import './components/cta-hero';
import './components/branch-page';
import './components/winkworth-academy';
import './components/image-content-blocks';
import './components/shift-column';
import './components/kentico-form';
import './components/val-video';
import './components/cta-search-result';
import './components/image-zoom';
import './components/inform-widget';
import './components/slide-captcha';
import './components/franchising-hero';
import './components/content-block-new';
import './components/book-valuation';
import './components/office-reviews';
import './components/media-quote';
import './components/choose-list';
import './components/content-block';
import './components/tippy';
import './components/tell-us';
import './components/single-property';
import './components/office-hero';
import './components/adfenix-trigger';
import './components/custom-activity-logger-search';
import './components/regulation-schemes';
import './components/keen-slider';
import './components/callout-widget';

/* init app */
import app from './general/js/app';

app.init();

/* require svg */
const files = require.context('./general/svg', true, /^\.\/.*\.svg/);
files.keys().forEach(files);
